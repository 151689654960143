import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import HomeSvg from "./assets/HomeSvg";

import ShopCartSvg from "../../assets/SVG/ShopCartSvg";
import CompareSvg from "../../assets/SVG/CompareSvg";
import UserSvg from "./assets/UserSvg";
import HeartStrokeSvg from "./assets/HeartStrokeSvg";
import { useDispatch, useSelector } from "react-redux";
import { setShowLoginModal } from "../../store/MainSlice";
import { Link } from "react-router-dom";
import langData from "lang/data";

export default function MobileFooter() {
  const { isAuthed, favorites, compares, shopCartCount, currentLanguage } =
    useSelector(({ main }) => main);
  const dispatch = useDispatch();
  const [showAppBanner, setShowAppBanner] = useState(false);
  useEffect(() => {
    const lastClosedMonth = localStorage.getItem("appBannerClosedMonth");

    // Получаем текущий месяц в формате "YYYY-MM"
    const currentMonth = new Date().toISOString().slice(0, 7);

    if (lastClosedMonth !== currentMonth) {
      setShowAppBanner(true);
    }
  }, []);
  const handleCloseAppBanner = () => {
    const currentMonth = new Date().toISOString().slice(0, 7);
    localStorage.setItem("appBannerClosedMonth", currentMonth);
    setShowAppBanner(false);
  };
  return (
    <>
      {showAppBanner && (
        <div className={styles.containerTwo}>
          <div className={styles.containerApp}>
            <h5>{langData.footer.download_app[currentLanguage]}</h5>
            <div class={styles.closeWs} onClick={handleCloseAppBanner}>
              <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1L16 16M16 1L1 16" stroke="#282828"></path></svg>
            </div>
          </div>
          <div className={styles.socIconsContainer}>
            <img
              className={styles.ws_download_image}
              src="https://vlv.am/public/images/download-apple-logo.svg"
              alt="Download on the App Store"
              onClick={() =>
                window.open('https://apps.apple.com/am/app/vlv-centre/id6476923086', '_blank', 'noopener,noreferrer')
              }
            />
            <img
              className={styles.ws_download_image}
              src="https://vlv.am/public/images/download-google-play.svg"
              alt="Get it on Google Play"
              onClick={() =>
                window.open(
                  'https://play.google.com/store/apps/details?id=com.vlv&pcampaignid=web_share',
                  '_blank',
                  'noopener,noreferrer'
                )
              }
            />
          </div>
        </div>
      )}
      <div className={styles.container}>
        <Link to={`${currentLanguage !== "hy" ? "/" + currentLanguage : ""}`}>
          <HomeSvg />
        </Link>
        <Link to={`${currentLanguage !== "hy" ? "/" + currentLanguage : ""
          }/favorite`}>
          {favorites.length > 0 && <span>{favorites.length}</span>}
          {favorites.length ? (
            <svg
              width="22"
              height="21"
              viewBox="0 0 22 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.0352 0.840576C15.0034 0.856221 13.9942 1.13699 13.1093 1.65454C12.2244 2.17208 11.4953 2.90808 10.9956 3.78819C10.4959 2.90808 9.76679 2.17208 8.88193 1.65454C7.99706 1.13699 6.98777 0.856221 5.95597 0.840576C4.31116 0.910239 2.76149 1.61165 1.64551 2.79157C0.529532 3.97149 -0.0620383 5.534 4.13572e-05 7.13775C4.13572e-05 13.1893 10.039 20.1787 10.466 20.4753L10.9956 20.8406L11.5252 20.4753C11.9522 20.1805 21.9912 13.1893 21.9912 7.13775C22.0532 5.534 21.4617 3.97149 20.3457 2.79157C19.2297 1.61165 17.68 0.910239 16.0352 0.840576ZM10.9956 18.6415C8.01489 16.471 1.83264 11.1385 1.83264 7.13775C1.77 6.00758 2.16834 4.89911 2.94077 4.0541C3.71321 3.20909 4.79707 2.6961 5.95597 2.62701C7.11487 2.6961 8.19874 3.20909 8.97117 4.0541C9.74361 4.89911 10.1419 6.00758 10.0793 7.13775H11.9119C11.8493 6.00758 12.2476 4.89911 13.02 4.0541C13.7925 3.20909 14.8763 2.6961 16.0352 2.62701C17.1941 2.6961 18.278 3.20909 19.0504 4.0541C19.8229 4.89911 20.2212 6.00758 20.1586 7.13775C20.1586 11.1403 13.9763 16.471 10.9956 18.6415Z"
                fill="#E31335"
              ></path>
              <path
                d="M10.9956 18.6415C8.01489 16.471 1.83264 11.1385 1.83264 7.13775C1.77 6.00758 2.16834 4.89911 2.94077 4.0541C3.71321 3.20909 4.79707 2.6961 5.95597 2.62701C7.11487 2.6961 8.19874 3.20909 8.97117 4.0541C9.74361 4.89911 10.1419 6.00758 10.0793 7.13775H11.9119C11.8493 6.00758 12.2476 4.89911 13.02 4.0541C13.7925 3.20909 14.8763 2.6961 16.0352 2.62701C17.1941 2.6961 18.278 3.20909 19.0504 4.0541C19.8229 4.89911 20.2212 6.00758 20.1586 7.13775C20.1586 11.1403 13.9763 16.471 10.9956 18.6415Z"
                fill="#E31335"
              ></path>
            </svg>
          ) : (
            <HeartStrokeSvg />
          )}
        </Link>
        <Link to={`${currentLanguage !== "hy" ? "/" + currentLanguage : ""}/cart`}>
          {shopCartCount?.count > 0 && <span>{shopCartCount?.count}</span>}
          {shopCartCount?.count ? (
            <svg
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M25.5195 5.1934C25.2251 4.83585 24.8566 4.54829 24.44 4.35113C24.0235 4.15398 23.5692 4.05206 23.1093 4.05263H6.18931L6.14536 3.68085C6.05541 2.90807 5.68843 2.19555 5.11401 1.67839C4.53958 1.16122 3.79764 0.875362 3.02884 0.875L2.79651 0.875C2.51896 0.875 2.25278 0.986595 2.05652 1.18524C1.86026 1.38388 1.75 1.65329 1.75 1.93421C1.75 2.21513 1.86026 2.48455 2.05652 2.68319C2.25278 2.88183 2.51896 2.99342 2.79651 2.99342H3.02884C3.28517 2.99346 3.53257 3.0887 3.72411 3.2611C3.91566 3.4335 4.03804 3.67105 4.06803 3.9287L5.50803 16.3215C5.65753 17.6103 6.26934 18.7987 7.22736 19.6612C8.18537 20.5236 9.42288 21 10.705 21H21.6338C21.9113 21 22.1775 20.8884 22.3738 20.6898C22.57 20.4911 22.6803 20.2217 22.6803 19.9408C22.6803 19.6599 22.57 19.3905 22.3738 19.1918C22.1775 18.9932 21.9113 18.8816 21.6338 18.8816H10.705C10.0573 18.8797 9.42597 18.6752 8.89762 18.2959C8.36927 17.9166 7.96976 17.3813 7.75385 16.7632H20.2283C21.4551 16.7632 22.6429 16.327 23.5843 15.5307C24.5256 14.7344 25.1605 13.6286 25.3782 12.4066L26.1997 7.79482C26.2817 7.33725 26.2633 6.86702 26.1457 6.41746C26.0282 5.9679 25.8144 5.55001 25.5195 5.1934Z"
                fill="#E30613"
              ></path>
              <path
                d="M9.1875 26.25C10.3956 26.25 11.375 25.2706 11.375 24.0625C11.375 22.8544 10.3956 21.875 9.1875 21.875C7.97938 21.875 7 22.8544 7 24.0625C7 25.2706 7.97938 26.25 9.1875 26.25Z"
                fill="#E30613"
              ></path>
              <path
                d="M19.6875 26.25C20.8956 26.25 21.875 25.2706 21.875 24.0625C21.875 22.8544 20.8956 21.875 19.6875 21.875C18.4794 21.875 17.5 22.8544 17.5 24.0625C17.5 25.2706 18.4794 26.25 19.6875 26.25Z"
                fill="#E30613"
              ></path>
            </svg>
          ) : (
            <ShopCartSvg />
          )}
        </Link>
        <Link to={`${currentLanguage !== "hy" ? "/" + currentLanguage : ""
          }/compare`}>
          {compares.length > 0 && <span>{compares.length}</span>}
          <CompareSvg active={compares.length > 0} />
        </Link>
        <a onClick={() => {
          if (isAuthed) {
            document.location.href =
              "https://vlv.am/profile/dashboard?lang=" + currentLanguage;
          } else {
            dispatch(setShowLoginModal(true));
          }
        }}
        >
          <UserSvg />
        </a>
      </div>
    </>
  );
}
