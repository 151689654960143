import React, { useState } from "react";
import styles from "./style.module.css";
import VisaSvg from "./assets/VisaSvg";
import MasterSvg from "./assets/MasterSvg";
import ArcaSvg from "./assets/ArcaSvg";
import TelCellSvg from "./assets/TelCellSvg";
import IdramSvg from "./assets/IdramSvg";
import ExpressSvg from "./assets/ExpressSvg";
import MirSvg from "./assets/MirSvg";
import FacebookSvg from "../../assets/SVG/FacebookSvg";
import InstagramSvg from "../../assets/SVG/InstagramSvg";
import WhatsappSvg from "../../assets/SVG/WhatsappSvg";
import ViberSvg from "../../assets/SVG/ViberSvg";
import ButtonArrowSvg from "../../assets/SVG/ButtonArrowSvg";
import { useDispatch, useSelector } from "react-redux";
import langData from "../../lang/data";
import { userSubscribe } from "../../store/MainSlice";
import { Link } from "react-router-dom";

export default function Footer() {
  const [showInfoMenu, setShowInfoMenu] = useState(false);
  const [showAboutMenu, setShowAboutMenu] = useState(false);
  const [showContactMenu, setShowContactMenu] = useState(false);
  const [showPaymentMenu, setShowPaymentMenu] = useState(false);

  const [subscribeInputError, setSubscribeInputError] = useState(false);
  const [subscribeEmail, setSubscribeEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { currentLanguage } = useSelector(({ main }) => main);

  const dispatch = useDispatch();
  const validateEmail = (email) => {

    const re = /\S+@\S+\.\S+/; // Про 
    return re?.test(email);
  };
  const handleSubscribe = () => {
    if (!subscribeEmail) {
      setSubscribeInputError(true);
      // setErrorMessage("Please enter an email address");
      return;
    }

    if (!validateEmail(subscribeEmail)) {
      setSubscribeInputError(true);
      // setErrorMessage("Please enter a valid email address");
      return;
    }

    setSubscribeInputError(false);
    setErrorMessage("");
    dispatch(userSubscribe(subscribeEmail));
  };

  return (
    <footer className={styles.footer}>
      <div className="wrapper" style={{ margin: "0 auto" }}>
        <img
          className={styles.logo}
          alt="VLV"
          src={
            currentLanguage === "hy"
              ? require("../../assets/logo.png")
              : "https://vlv.am/public/logos/header_ru.svg"
          }
        />
      </div>
      <div className={styles.line} />
      <div className={`wrapper ${styles.menuContainer}`}>
        <div className={styles.menu}>
          <div
            className={styles.titleBlock}
            onClick={() => {
              window.innerWidth < 768 && setShowInfoMenu(!showInfoMenu);
            }}
          >
            <h5>{langData.footer.Information.title[currentLanguage]}</h5>
            <ButtonArrowSvg
              style={showInfoMenu ? { transform: "rotate(180deg)" } : {}}
            />
          </div>
          <div
            className={styles.menuList}
            style={
              !showInfoMenu && window.innerWidth < 768
                ? { height: 0, overflow: "hidden" }
                : {}
            }
          >
            <Link
              to={`${currentLanguage !== "hy" ? "/" + currentLanguage : ""
                }/sales`}
            >
              <span>
                {langData.footer.Information.menu.Promotion[currentLanguage]}
              </span>
            </Link>
            <Link
              to={`${currentLanguage !== "hy" ? "/" + currentLanguage : ""
                }/delivery`}
            >
              <span>
                {langData.footer.Information.menu.Delivery[currentLanguage]}
              </span>
            </Link>
            <Link
              to={`${currentLanguage !== "hy" ? "/" + currentLanguage : ""
                }/payment`}
            >
              <span>
                {langData.footer.Information.menu.Payment[currentLanguage]}
              </span>
            </Link>
            <Link
              to={`${currentLanguage !== "hy" ? "/" + currentLanguage : ""
                }/credit`}
            >
              <span>
                {langData.footer.Information.menu.Credit[currentLanguage]}
              </span>
            </Link>
            <Link
              to={`${currentLanguage !== "hy" ? "/" + currentLanguage : ""
                }/order`}
            >
              <span>
                {langData.footer.Information.menu.Order[currentLanguage]}
              </span>
            </Link>
            <Link
              to={`${currentLanguage !== "hy" ? "/" + currentLanguage : ""
                }/article`}
            >
              <span>
                {langData.footer.Information.article[currentLanguage]}
              </span>
            </Link>
          </div>
        </div>
        <div className={styles.menu}>
          <div
            className={styles.titleBlock}
            onClick={() => {
              window.innerWidth < 768 && setShowAboutMenu(!showAboutMenu);
            }}
          >
            <h5>{langData.footer.To_the_buyer.title[currentLanguage]}</h5>
            <ButtonArrowSvg
              style={showAboutMenu ? { transform: "rotate(180deg)" } : {}}
            />
          </div>
          <div
            className={styles.menuList}
            style={
              !showAboutMenu && window.innerWidth < 768
                ? { height: 0, overflow: "hidden" }
                : {}
            }
          >
            <Link
              to={`${currentLanguage !== "hy" ? "/" + currentLanguage : ""
                }/about-us`}
            >
              <span>
                {langData.footer.To_the_buyer.menu.About_us[currentLanguage]}
              </span>
            </Link>
            <Link
              to={`${currentLanguage !== "hy" ? "/" + currentLanguage : ""
                }/faq`}
            >
              <span>
                {langData.footer.To_the_buyer.menu.FAQ[currentLanguage]}
              </span>
            </Link>
            <Link
              to={`${currentLanguage !== "hy" ? "/" + currentLanguage : ""
                }/job`}
            >
              <span>
                {langData.footer.To_the_buyer.menu.Jobs[currentLanguage]}
              </span>
            </Link>
            <Link
              to={`${currentLanguage !== "hy" ? "/" + currentLanguage : ""
                }/contacts`}
            >
              <span>
                {langData.footer.To_the_buyer.menu.Our_stores[currentLanguage]}
              </span>
            </Link>
            <Link
              to={`${currentLanguage !== "hy" ? "/" + currentLanguage : ""
                }/service`}
            >
              <span>
                {langData.footer.To_the_buyer.menu.Service[currentLanguage]}
              </span>
            </Link>
            <Link
              to={`${currentLanguage !== "hy" ? "/" + currentLanguage : ""
                }/privacy`}
            >
              <span>
                {
                  langData.footer.To_the_buyer.menu.Privacy_Policy[
                  currentLanguage
                  ]
                }
              </span>
            </Link>
          </div>
        </div>
        <div className={styles.menu}>
          <div
            className={styles.titleBlock}
            onClick={() => {
              window.innerWidth < 768 && setShowContactMenu(!showContactMenu);
            }}
          >
            <h5>{langData.footer.Contact.title[currentLanguage]}</h5>
            <ButtonArrowSvg
              style={showContactMenu ? { transform: "rotate(180deg)" } : {}}
            />
          </div>
          <div
            className={styles.menuList}
            style={
              !showContactMenu && window.innerWidth < 768
                ? { height: 0, overflow: "hidden" }
                : {}
            }
          >
            <span>{langData.footer.Contact.menu.addres[currentLanguage]}</span>
            <span>info@vlv.am</span>
            <span>010-34-99-44</span>
          </div>
        </div>
        <div className={styles.menu}>
          <div
            className={styles.titleBlock}
            onClick={() => {
              window.innerWidth < 768 && setShowPaymentMenu(!showPaymentMenu);
            }}
          >
            <h5>{langData.footer.Types_of_payment.title[currentLanguage]}</h5>
            <ButtonArrowSvg
              style={showPaymentMenu ? { transform: "rotate(180deg)" } : {}}
            />
          </div>
          <div
            className={styles.menuList}
            style={
              !showPaymentMenu && window.innerWidth < 768
                ? { height: 0, overflow: "hidden" }
                : {}
            }
          >
            <p className={styles.menuText}>
              {langData.footer.Types_of_payment.description[currentLanguage]}{" "}
              <Link
                to={`${currentLanguage !== "hy" ? "/" + currentLanguage : ""
                  }/payment`}
              >
                {
                  langData.footer.Types_of_payment.description_under_line[
                  currentLanguage
                  ]
                }
              </Link>
            </p>
            <div className={styles.paymentTypes}>
              <Link
                to={`${currentLanguage !== "hy" ? "/" + currentLanguage : ""
                  }/payment`}
              >
                <VisaSvg />
              </Link>
              <Link
                to={`${currentLanguage !== "hy" ? "/" + currentLanguage : ""
                  }/payment`}
              >
                <MasterSvg />
              </Link>
              <Link
                to={`${currentLanguage !== "hy" ? "/" + currentLanguage : ""
                  }/payment`}
              >
                <ArcaSvg />
              </Link>
              <Link
                to={`${currentLanguage !== "hy" ? "/" + currentLanguage : ""
                  }/payment`}
              >
                <TelCellSvg />
              </Link>
              <Link
                to={`${currentLanguage !== "hy" ? "/" + currentLanguage : ""
                  }/payment`}
              >
                <IdramSvg />
              </Link>
              <Link
                to={`${currentLanguage !== "hy" ? "/" + currentLanguage : ""
                  }/payment`}
              >
                <ExpressSvg />
              </Link>
              {/* <a href={"https://vlv.am/pay?lang=" + currentLanguage}>
                <MirSvg />
              </a> */}
            </div>
          </div>
        </div>
        <div className={styles.menu}>
          <div className={styles.menuText}>
            {langData.footer.subscribe_desc[currentLanguage]}
          </div>
          <div className={styles.input}>
            <input
              placeholder={langData.footer.input_placeholder[currentLanguage]}
              type="email"
              value={subscribeEmail}
              onChange={(e) => {
                setSubscribeEmail(e?.target?.value);
                if (subscribeInputError) {
                  setSubscribeInputError(false);
                  setErrorMessage("");
                }
              }}
              className={`${subscribeInputError ? styles.inputError : ""}`}
            />
            {subscribeInputError && (
              <p className={styles.errorMessage}>{errorMessage}</p>
            )}
            <div className={styles.inputBtn} onClick={handleSubscribe}>
              <span>{langData.footer.input_btn[currentLanguage]}</span>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.line} />
      <div style={{ display: "none" }}>
        Կայքի պատրաստում: <a href="https://webstart.am" title="Webstart.am">Webstart.am</a>
      </div>
      <div className={`${styles.appStyle} wrapper `}>
        <div className={styles.ws_mb_2}>
          <h5>{langData.footer.download_app[currentLanguage]}</h5>
          <div className={styles.socIconsContainer}>
            <img
              className={styles.ws_download_image}
              src="https://vlv.am/public/images/download-apple-logo.svg"
              alt="Download on the App Store"
              onClick={() =>
                window.open('https://apps.apple.com/am/app/vlv-centre/id6476923086', '_blank', 'noopener,noreferrer')
              }
            />
            <img
              className={styles.ws_download_image}
              src="https://vlv.am/public/images/download-google-play.svg"
              alt="Get it on Google Play"
              onClick={() =>
                window.open(
                  'https://play.google.com/store/apps/details?id=com.vlv&pcampaignid=web_share',
                  '_blank',
                  'noopener,noreferrer'
                )
              }
            />
          </div>
        </div>
        <div>
          <h5>{langData.footer.weOn_soc_media[currentLanguage]}</h5>
          <div className={styles.socIconsContainer}>
            <a
              href="https://www.facebook.com/people/VLV-Electronics/100064824965577/?mibextid=LQQJ4d"
              aria-label="facebook"
            >
              <FacebookSvg />
            </a>
            <a
              href="https://www.instagram.com/vlv_centre/"
              aria-label="instagram"
            >
              <InstagramSvg />
            </a>
            <a href="https://wa.me/37491296655" aria-label="whatsapp">
              <WhatsappSvg />
            </a>
            <a href="viber://chat?number=+37491296655/" aria-label="viber">
              <ViberSvg />
            </a>
          </div>
        </div>
      </div>
      <div className={styles.line} />
      <div className={`wrapper ${styles.copyrightContainer}`}>
        <span className={styles.updateAtText}>
          {langData.footer.update_at[currentLanguage]}
          {" " +
            new Date().toLocaleDateString() +
            " " +
            new Date().toLocaleTimeString()}
        </span>
        <p className={styles.copyright}>
          {langData.footer.copy_right[currentLanguage]}
          {new Date().getFullYear()}
        </p>
      </div>
    </footer>
  );
}
