const langData = {
  GroupBuyNow: {
    hy: "Գնել հիմա",
    ru: "Купить сейчас",
    en: "Buy Now",
  },
  header: {
    noHistory: {
      "en": "You haven't searched for anything yet",
      "hy": "Դուք դեռ ոչինչ չեք որոնել",
      "ru": "Вы еще ничего не искали"
    },
    searchResult: {
      "en": "Search Results",
      "hy": "Որոնման արդյունքները",
      "ru": "Результаты поиска"
    },
    refresh: {
      "en": "Refresh",
      "hy": "Թարմացնել",
      "ru": "Обновить"
    },
    cleanHistory: {
      "en": "Clear History",
      "hy": "Մաքրել պատմությունը",
      "ru": "Очистить историю"
    },
    recommendedForYou: {
      "en": "Recommended for you",
      "hy": "Առաջարկվում է ձեզ համար",
      "ru": "Рекомендуется для вас"
    },
    recentSearches: {
      "en": "Recent searches",
      "hy": "Վերջին որոնումները",
      "ru": "Недавние запросы"
    },
    menu: {
      Our_shops: {
        hy: "Մեր խանութները",
        ru: "Наши магазины",
        en: "Our shops",
      },
      Blog: {
        hy: "Բլոգ",
        ru: "Блог",
        en: "Blog",
      },
      Jobs: {
        hy: "Աշխատանք",
        ru: "Вакансии",
        en: "Jobs",
      },
      About_us: {
        hy: "Մեր մասին",
        ru: "О нас",
        en: "About us",
      },
      Delivery: {
        hy: "Առաքում",
        ru: "Доставка",
        en: "Delivery",
      },
      Credit: {
        hy: "Ապառիկ",
        ru: "Кредит",
        en: "Credit",
      },
      Contacts: {
        hy: "Կապ",
        ru: "Контакты",
        en: "Contacts",
      },
      three_one_price: {
        hy: "3-ը 1-ի գնով",
        ru: "3 по цене 1",
        en: "3 in 1 price",
      },
      GroupPurchaseOfProducts: {
        hy: "Ապրանքների գրուպավորված գնում",
        ru: "Групповая закупка продукции",
        en: "Group purchase of products",
      },
    },
    langugae: {
      hy: "Լեզու",
      ru: "Язык",
      en: "Language",
    },
    сurrency: {
      hy: "Արժույթ",
      ru: "Валюта",
      en: "Currency",
    },
    searchPlaceholder: {
      hy: "Փնտրիր որ գտնես",
      ru: "Ищите, чтобы найти",
      en: "Search to find",
    },
  },
  modalTextAmeria:{
    text: {
      hy: "Մինչ ամերիա բանկում անձնական տվյալների լրացումը, ապրանքի վաճառքի մանրամասների հետ կապված զանգահարել 096018166 կամ 091767117",
      ru: "Перед заполнением личных данных в банке Америя, пожалуйста, свяжитесь с нами по телефонам 096018166 или 091767117 для получения дополнительной информации о продаже продукции.",
      en: "Prior to completing your personal information at Ameria Bank, please contact us at 096018166 or 091767117 for further details regarding product sales",
    },
    buttons:{
      hy:"Տեղափոխվել բանկի էջ",
      ru: "Перейти на страницу банка",
      en: "Go to the bank page",
    }
  },
  SpecialOffer: {
    title: {
      hy: "ՀԱՏՈՒԿ ԱՌԱՋԱՐԿ",
      ru: "СПЕЦИАЛЬНОЕ ПРЕДЛОЖЕНИЕ",
      en: "GET TO BUY",
    },
    sub_title: {
      hy: "ԱԿՑԻԱՆ ՀԱՍԱՆԵԼԻ Է ",
      ru: "АКЦИЯ ДОСТУПНА ",
      en: "THE PROMOTION IS AVAILABLE ",
    },
    days: { hy: "Օր", ru: "Дни", en: "DAYS" },
    hours: { hy: "Ժամ", ru: "Часов", en: "Hours" },
    minutes: { hy: "Րոպե", ru: "Часов", en: "Minutes" },
    seconds: { hy: "Վայրկյան", ru: "Секунд", en: "Seconds" },
  },
  treeInOne: {
    redTitle: {
      hy: "ՇՏԱՊԻ՜Ր ԳՆԵԼ ՀԱՎԱՔԱԾՈՒՆ",
      ru: "СПЕШИ КУПИТЬ КОЛЛЕКЦИЮ",
      en: "HURRY TO BUY",
    },
    blackTitle: {
      hy: "ՄԻԱՅՆ ՕՆԼԱՅՆ ԳՆՄԱՆ ԴԵՊՔՈՒՄ",
      ru: "ОЛЬКО ДЛЯ ОНЛАЙН-ПОКУПКИ.",
      en: "ONLY FOR ONLINE",
    },
  },
  buttons: {
    views: {
      hy: "Դիտում",
      ru: "Просмотр",
      en: "View",
    },
    catalog: {
      hy: "Բաժիններ",
      ru: "Категории",
      en: "All Category",
    },
    search: {
      hy: "Որոնել",
      ru: "Поиск",
      en: "Search",
    },

    cart: {
      hy: "Զամբյուղ",
      ru: "Корзина",
      en: "Cart",
    },
    view_all: {
      hy: "Տեսնել բոլորը",
      ru: "Посмотреть все",
      en: "View All",
    },
    buy_now: {
      hy: "Գնել հիմա",
      ru: "КУПИТЬ СЕЙЧАС",
      en: "BUY NOW",
    },
    quick_buy: {
      hy: "Արագ գնում",
      ru: "Быстрая покупка",
      en: "Quick buy",
    },
    buy_on_credit: {
      hy: "Գնել ապառիկ",
      ru: "Купить в кредит",
      en: "Buy on credit",
    },
    buy_on_credit_now: {
      hy: "Գնել ապառիկ հիմա",
      ru: "Покупайте в кредит прямо сейчас!",
      en: "Buy on credit now",
    },
    add_to_cart: {
      hy: "Ավելացնել",
      ru: "Добавить",
      en: "Add to Cart",
    },
  },
  titles: {
    FrequentQuestions: {
      hy: "ՀԱՃԱԽ ՏՐՎՈՂ ՀԱՐՑԵՐ",
      ru: "Вопрос / Ответ",
      en: "FAQ",
    },
    SpecialPrices: {
      hy: "ՀԱՏՈՒԿ ԳՆԵՐ",
      ru: "СПЕЦИАЛЬНЫЕ ЦЕНЫ",
      en: "SPECIAL PRICES",
    },
    Values: {
      hy: "ԱՐԺԵՔ",
      ru: "ЦЕНА",
      en: "PRICE",
    },

    BlogTitleSecond: {
      hy: "ՎԵՐՋԻՆ ՆՈՐՈՒԹՅՈՒՆՆԵՐԸ",
      ru: "ПОСЛЕДНИЕ НОВОСТИ",
      en: "LATEST NEWS",
    },
    FeatureCategories: {
      hy: "ՀԱՅՏՆԻ ԲԱԺԻՆՆԵՐ",
      ru: "ПОПУЛЯРНЫЕ КАТЕГОРИИ",
      en: "POPULAR CATEGORIES",
    },
    Manufactures: {
      hy: "ԱՐՏԱԴՐՈՂՆԵՐ",
      ru: "ПРОИЗВОДИТЕЛИ",
      en: "MANUFACTURERS",
    },
    Categories: {
      hy: "ԿԱՏԵԳՈՐԻԱ",
      ru: "КАТЕГОРИЯ",
      en: "CATEGORY",
    },
    DiscountedProducts: {
      hy: "ԶԵՂՉՎԱԾ ԱՊՐԱՆՔՆԵՐ",
      ru: "ПРОДУКЦИЯ СО СКИДКОЙ",
      en: "DISCOUNTED PRODUCTS",
    },
    ManufacturerCountry: {
      hy: "ԱՐՏԱԴՐՈՂ ԵՐԿԻՐ",
      ru: "СТРАНА ИЗГОТОВИТЕЛЬ",
      en: "MANUFACTURER COUNTRY",
    },
    PrivacyPolicy: {
      hy: "ԳԱՂՏՆԻՈՒԹՅԱՆ ՔԱՂԱՔԱԿԱՆՈՒԹՅՈՒՆ",
      ru: "ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ",
      en: "PRIVACY POLICY",
    },

    jobs: {
      hy: "ԱՇԽԱՏԱՏԵՂԵՐ",
      ru: "ВАКАНСИИ",
      en: "JOBS",
    },
    Sales: {
      hy: "ԱԿՑԻԱՆԵՐ",
      ru: "АКЦИИ",
      en: "OUR PROMOTIONS",
    },
    BestDealProduct: {
      hy: "ՎԱՃԱՌՔԻ ՀԻԹԵՐ",
      ru: "ХИТЫ ПРОДАЖ",
      en: "SALES HITS",
    },
    TheyGoWithThisProduct: {
      hy: "Այս ապրանքի հետ գնում են",
      ru: "Они идут с этим продуктом",
      en: "They go with this product",
    },
    TopRatingProduct: {
      hy: "ՆՈՐ ԱՊՐԱՆՔՆԵՐ",
      ru: "НОВЫЕ ПРОДУКТЫ",
      en: "NEW PRODUCTS",
    },
    TopPicksProduct: {
      hy: "ՇԱՏ ԴԻՏՎԱԾ ԱՊՐԱՆՔՆԵՐ",
      ru: "ПОПУЛЯРНЫЕ ТОВАРЫ",
      en: "MANY VIEWED PRODUCTS",
    },
    Blog: {
      hy: "ԲԼՈԳ",
      ru: "БЛОГ",
      en: "NEWS",
    },
    Brand: {
      hy: "ԱՊՐԱՆՔԱՆԻՇԵՐ",
      ru: "ПРОИЗВОДИТЕЛИ",
      en: "BRANDS",
    },
    Search: {
      hy: "ՈՐՈՆԵԼ",
      ru: "ПОИСК",
      en: "SEARCH",
    },
    SearchResult: {
      hy: "ՈՐՈՆՄԱՆ ԱՐԴՅՈՒՆՔՆԵՐ",
      ru: "РЕЗУЛЬТАТЫ ПОИСКА",
      en: "SEARCH RESULTS",
    },
    SearchNull: {
      hy: "ՁԵՐ ՀԱՐՑՈՒՄՈՎ ՈՉԻՆՉ ՉԻ ԳՏՆՎԵԼ",
      ru: "ПО ВАШЕМУ ЗАПРОСУ НИЧЕГО НЕ НАЙДЕНО",
      en: "NOTHING WAS FOUND FOR YOUR QUERY",
    },
    ProductWithSlide: {
      hy: "ՁԵԶ ԿԱՐՈՂ Է ՀԵՏԱՔՐՔՐԵԼ",
      ru: "ВОЗМОЖНО, ВАМ БУДЕТ ИНТЕРЕСНО",
      en: "YOU MAY BE INTERESTED",
    },
  },
  month: {
    hy: "Ամիս",
    ru: "Месяц",
    en: "Month",
  },
  footer: {
    Information: {
      title: {
        hy: "Տեղեկատվություն",
        ru: "Информация",
        en: "Information",
      },
      article:{
        hy: "Հոդված",
        ru: "Статья",
        en: "Article",
      },
      menu: {
        Promotion: {
          hy: "Ակցիաներ",
          ru: "Акции",
          en: "Promotion",
        },
        Delivery: {
          hy: "Առաքում",
          ru: "Доставка",
          en: "Delivery",
        },
        Payment: {
          hy: "Վճարում",
          ru: "Оплата",
          en: "Payment",
        },
        Credit: {
          hy: "Ապառիկ",
          ru: "Кредит",
          en: "Credit",
        },
        Order: {
          hy: "Պատվեր",
          ru: "Заказ",
          en: "Order",
        },
      },
    },
    To_the_buyer: {
      title: {
        hy: "«ՎիԷլՎի սենթր»-ի մասին",
        ru: "Покупателю",
        en: "To the buyer",
      },
      menu: {
        About_us: {
          hy: "Մեր մասին",
          ru: "О нас",
          en: "About us",
        },
        FAQ: {
          hy: "ՀՏՀ",
          ru: "FAQ",
          en: "FAQ",
        },
        Jobs: {
          hy: "Աշխատատեղեր",
          ru: "Рабочие места",
          en: "Jobs",
        },
        Our_stores: {
          hy: "Մեր խանութները",
          ru: "Наши магазины",
          en: "Our stores",
        },
        Service: {
          hy: "Սպասարկում",
          ru: "Обслуживание",
          en: "Service",
        },
        Privacy_Policy: {
          hy: "Գաղտնիության քաղաքականություն",
          ru: "Политика конфиденциальности",
          en: "Privacy Policy",
        },
      },
    },
    Contact: {
      title: {
        hy: "Կապ",
        ru: "Контакт",
        en: "Contact",
      },
      menu: {
        addres: {
          hy: "Արշակունյաց 69/5",
          ru: "Аршакуняц 69/5",
          en: "69/5 Arshakuniats Avenue",
        },
      },
    },
    Types_of_payment: {
      title: {
        hy: "Վճարման տարբերակներ",
        ru: "Принимаем к оплате",
        en: "Types of payment",
      },
      description: {
        hy: "Ընդունում ենք վճարումներ կանխիկ և անկանխիկ",
        ru: "Вы можете оплатить наличными при доставке или выбрать",
        en: "You can pay by cash on delivery or choose",
      },
      description_under_line: {
        hy: "Վճարման տարբերակներ",
        ru: "другой способ оплаты",
        en: "another payment method",
      },
    },
    subscribe_desc: {
      hy: "Բաժանորդագրվեք ակցիաներին և  նորություններին",
      ru: "Новости и рекламные акции только для участников",
      en: "News and promotions for members only",
    },
    input_placeholder: {
      hy: "Էլ․ հասցե",
      ru: "Ваш e-mail:",
      en: "Enter email address",
    },
    input_btn: {
      hy: "Բաժանորդագրվել",
      ru: "Подписываться",
      en: "Subscribe",
    },
    weOn_soc_media: {
      hy: "Մենք սոց․ ցանցերում",
      ru: "Мы в социальных сетях",
      en: "We on social media",
    },
    download_app:{
      hy: "Ներբեռնիր հավելվածը",
      ru: "Загрузить приложение",
      en: "Download the app",
    },
    copy_right: {
      hy: "© Հեղինակային իրավունքները պաշտպանված են <<ՎիէլՎի Սենթր>> ՍՊԸ, 2014-",
      ru: "© Авторские права защищены ВИЭЛВИ Центр ООО, 2014-",
      en: "© Copyright VLV CENTRE LLC, 2014-",
    },
    update_at: {
      hy: "Թարմացվել է",
      ru: "Обновлено ",
      en: "update at",
    },
    subscribe_modal: {
      title: {
        hy: "ԴՈՒՔ ԱՐԴԵՆ ԲԱԺԱՆՈՐԴԱԳՐՎԱԾ ԵՔ.",
        ru: "ВЫ УЖЕ ПОДПИСАНЫ.",
        en: "YOU ARE ALREADY SUBSCRIBED.",
      },
      btn: {
        hy: "Փակել",
        ru: "Закрыть",
        en: "Close",
      },
    },
  },
  loginModal: {
    password_mismatch_error: {
      en: "Passwords do not match",
      ru: "Пароли не совпадают",
      hy: "Գաղտնաբառերը չեն համընկնում",
    },
    reset_failure: {
      en: "Failed to reset password. Please try again later.",
      ru: "Не удалось сбросить пароль. Пожалуйста, попробуйте позже.",
      hy: "Չհաջողվեց վերականգնել գաղտնաբառը։ Խնդրում ենք փորձել կրկին։",
    },
    password_reset_success: {
      en: "Your password has been successfully reset",
      ru: "Ваш пароль был успешно сброшен",
      hy: "Ձեր գաղտնաբառը հաջողությամբ վերականգնվել է",
    },
    we_send: {
      en: "We have sent a reset code to your email",
      ru: "Мы отправили код сброса на вашу электронную почту",
      hy: "Մենք ուղարկել ենք վերականգնման կոդ ձեր էլ. հասցեին",
    },
    email_error: {
      en: "Please enter a valid email address or telephone number.",
      ru: "Пожалуйста, введите действительный адрес электронной почты или номер телефона.",
      hy: "Խնդրում ենք մուտքագրել վավեր էլ․ հասցե կամ հեռախոսահամար։",
    },
    login: {
      hy: "Մուտք",
      ru: "Вход",
      en: "Login",
    },
    loginPage: {
      hy: "Մուտք անձնական էջ",
      ru: "Вход на личную страницу",
      en: "Login to personal page",
    },
    personalCartTitle: {
      hy: "Անձնական տվյալներ",
      ru: "Персональная информация",
      en: "Personal information",
    },
    personalCartButton: {
      hy: "Պատվիրել այլ անձի տվյալներով",
      ru: "Заказ с данными другого человека",
      en: "Honor another person's data",
    },
    signup: {
      hy: "Գրանցվել",
      ru: "Регистрация",
      en: "Sign Up",
    },
    forgotPassword: {
      hy: "Մոռացել ե՞ք գաղտնաբառը",
      ru: "Забыли пароль?",
      en: "Forgot Password?",
    },
    forgotPasswordText: {
      hy: "Խնդրում եմ մուտքագրեք Ձեր էլեկտորանյին հասցեին ուղարկված վեցանիշ ծածկագիրը",
      ru: "Пожалуйста, введите шестизначный код, отправленный по электронной почте.",
      en: "Please, enter the six-digit code provided in the email.",
    },
    enterLoginAndPassword: {
      hy: "Մուտքագրեք Ձեր անունը և գաղտնաբառը",
      ru: "Введите свое имя пользователя и пароль",
      en: "Enter your username and password",
    },
    enterLogin: {
      hy: "Մուտքագրեք ձեր մուտքանունը և գաղտնաբառը",
      ru: "Введите свое имя пользователя и пароль",
      en: "Enter your username and password",
    },
    enterLoginSocial: {
      hy: "Մուտք գործել սոց. ցանցերի միջոգով",
      ru: "Войти в соц. через сети",
      en: "Login to social. through networks",
    },
    email: {
      hy: "Էլ․հասցե կամ հեռախոսահամար:",
      ru: "Электронная почта или номер телефона.",
      en: "Email adress or telephone number.",
    },
    Modalemail: {
      hy: "Էլ․հասցե",
      ru: "Электронная почта",
      en: "Email adress",
    },
    ModaPhone: {
      hy: "Բջջային հեռ.",
      ru: "Мобильный телефон",
      en: "Mobile phone",
    },

    password: {
      hy: "Գաղտնաբառ",
      ru: "пароль",
      en: "password",
    },
    FirstName: {
      hy: "Անուն",
      ru: "Имя",
      en: "First Name",
    },
    LastName: {
      hy: "Ազգանուն",
      ru: "Фамилия",
      en: "Last Name",
    },
    RepeatPassword: {
      hy: "Կրկնել գաղտնաբառը",
      ru: "Повторите пароль",
      en: "Repeat the password",
    },
    FillInYourPersonalInformation: {
      hy: "Լրացրեք Ձեր անձնական տվյալները",
      ru: "Заполните свою личную информацию",
      en: "Fill in your personal information",
    },
    next: {
      hy: "Առաջ",
      ru: "Следующий",
      en: "Next",
    },
    error: {
      hy: "Խնդրում ենք մուտքագրել գործող էլեկտրոնային հասցե.",
      ru: "Пожалуйста, введите действительный адрес электронной почты.",
      en: "Please enter a valid email address.",
    },
  },
  PaymentCardText: {
    card1: {
      hy: "ՎՃԱՐԵԼ ՏԵՂՈՒՄ ԿԱՆԽԻԿ ԿԱՄ ԲԱՆԿԱՅԻՆ ՔԱՐՏՈՎ",
      ru: "ОПЛАТА НА МЕСТЕ НАЛИЧНЫМИ ИЛИ БАНКОВСКОЙ КАРТОЙ",
      en: "PAY ON THE SPOT BY CASH OR BANK CARD",
    },
    card2: {
      hy: "ԻԴՐԱՄ",
      ru: "ИДРАМ:",
      en: "IDRAM",
    },
    card3: {
      hy: "TELCELL ԴՐԱՄԱՊԱՆԱԿ",
      ru: "TELCELL КОШЕЛЕК",
      en: "TELCELL WALLET ",
    },
    card4: {
      hy: "AMERICAN EXPRESS",
      ru: "АМЕРИКАН ЭКСПРЕСС",
      en: "AMERICAN EXPRESS",
    },
    card5: {
      hy: "ՕՆԼԱՅՆ ԱՊԱՌԻԿ",
      ru: "ОНЛАЙН КРЕДИТ",
      en: "ONLINE CREDIT",
    },

    card6: {
      hy: "ԲԱՆԿԱՅԻՆ ՔԱՐՏՈՎ",
      ru: "БАНКОВСКОЙ КАРТОЙ",
      en: "BY BANK CARD",
    },

    card7: {
      hy: "ՎՃԱՐԵԼ ՀԱՇՎԻ ԱՊՐԱՆՔԱԳՐՈՎ",
      ru: "ОПЛАТА ПО ЧЕКУ",
      en: "PAY BY RECEIPT",
    },
    card8: {
      hy: "ՎՃԱՐՈՒՄ ԲՈՆՈՒՍՆԵՐՈՎ",
      ru: "ОПЛАТА БОНУСАМИ",
      en: "PAYMENT WITH BONUSES",
    },
    card9: {
      hy: "EASY PAY",
      ru: "EASY PAY",
      en: "EASY PAY",
    },
  },
  meta: {
    title: {
      en: "VLV Centre | ՎիԷլՎի Սենթր | The Biggest Showroom in the Caucasus Region",
      hy: "ՎիԷլՎի Սենթր | VLV Centre | Ամենամեծ Խանութ-Սրահը Տարածաշրջանում",
      ru: "VLV Centre | ՎիԷլՎի Սենթր | Самый Большой Магазин в Регионе",
    },
    description: {
      en: "VLV.am | Furniture, Electronics, Smartphones, Computers, and more.",
      ru: "VLV.am | Мебель, Электроника, Смартфоны, Компьютеры и многое другое. Постоянные акции, гарантированное качество и выгодные предложения.",
      hy: "VLV.am | Կահույք, Էլեկտրատեխնիկա, Սմարթֆոններ, Համակարգիչներ և ավելին։ Մշտապես գործող ակցիաներ, երաշխավորված որակ և շահավետ գնառաջարկներ։",
    },
  },
  months: {
    1: {
      en: "Jan",
      hy: "Հուն",
      ru: "Янв",
    },
    2: {
      en: "Feb",
      hy: "Փետ",
      ru: "Фев",
    },
    3: {
      en: "Mar",
      hy: "Մար",
      ru: "Мар",
    },
    4: {
      en: "Apr",
      hy: "Ապր",
      ru: "Апр",
    },
    5: {
      en: "May",
      hy: "Մայ",
      ru: "Май",
    },
    6: {
      en: "Jun",
      hy: "Հուն",
      ru: "Июн",
    },
    7: {
      en: "Jul",
      hy: "Հուլ",
      ru: "Июл",
    },
    8: {
      en: "Aug",
      hy: "Օգո",
      ru: "Авг",
    },
    9: {
      en: "Sep",
      hy: "Սեպ",
      ru: "Сен",
    },
    10: {
      en: "Oct",
      hy: "Հոկ",
      ru: "Окт",
    },
    11: {
      en: "Nov",
      hy: "Նոյ",
      ru: "Ноя",
    },
    12: {
      en: "Dec",
      hy: "Դեկ",
      ru: "Дек",
    },
  },
  profile: {
    menu_list: {
      personal_page: {
        hy: "Անձնական էջ",
        ru: "Персональная страница",
        en: "Personal page",
      },
      personal_info: {
        hy: "Անձնական տվյալներ",
        ru: "Персональная информация",
        en: "Personal information",
      },
      my_address: {
        hy: "Իմ հասցեները",
        ru: "Мои адреса",
        en: "My addresses",
      },
      order_history: {
        hy: "Պատվերի պատմություն",
        ru: "История заказов",
        en: "Order history",
      },
      virtual_goods: {
        hy: "Վիրտուալ ապրանքներ",
        ru: "Виртуальные товары",
        en: "Our shops",
      },
      favorite_products: {
        hy: "Հավանած ապրանքնր",
        ru: "Любимые продукты",
        en: "Favorite products",
      },
      go_out: {
        hy: "Դուրս գալ",
        ru: "Выходить",
        en: "Go out",
      },
    },
  },

  product: {
    color: {
      en: "Color",
      ru: "Цвет",
      hy: "Գույն",
    },
    not_found: {
      en: "Not Found",
      ru: "Не найдено",
      hy: "Չի գտնվել",
    },
    price: {
      en: "Cash Price",
      ru: "Наличные Цена",
      hy: "Կանխիկ Գին",
    },
    show_more: {
      en: "SHOW MORE",
      ru: "УЗНАТЬ БОЛЬШЕ",
      hy: "ՏԵՍՆԵԼ Ավելին",
    },
    record: {
      en: "Point out",
      ru: "Указать",
      hy: "Ցույ տալ",
    },
    sorting: {
      en: "Sorting",
      ru: "Сортировка",
      hy: "Տեսակավորում",
    },
    cost: {
      en: "Cost",
      ru: "Ценить",
      hy: "Արժեք",
    },
    manufactor: {
      en: "Manufacturers",
      ru: "Производители",
      hy: "Արտադրողներ",
    },
    discount: {
      en: "Discounted products",
      ru: "Товары со скидкой",
      hy: "Զեղչված ապրանքներ",
    },
    save: {
      en: "Save",
      ru: "Сохранять",
      hy: "Պահպանել",
    },
    filter: {
      en: "Filter",
      ru: "Фильтр",
      hy: "ֆիլտր ",
    },
    enableRange: {
      en: "Enable Filter",
      ru: "Включить фильтр",
      hy: "Միացնել",
    },
  },
  brand: {
    category: {
      en: "CATEGORY",
      ru: "КАТЕГОРИЯ",
      hy: "ԲԱԺԻՆ",
    },
  },
  reviewModal: {
    review_title: {
      en: "Leave your feedback for site improvement work",
      ru: "Оставьте свой отзыв о работе по улучшению сайта",
      hy: "Կայքի բարելավման աշխատանքների համար թողեք ձեր կարծիքը",
    },
    form: {
      en: "Leave a comment",
      ru: "Оставить комментарий",
      hy: "Թողնել մեկնաբանություն",
    },
    button_1: {
      en: "Leave a review",
      ru: "Оставить отзыв",
      hy: "Թողնել կարծիք",
    },
    thanks_comment: {
      en: "Thank you for your feedback",
      ru: "Спасибо за ваш отзыв",
      hy: "Շնորհակալություն ձեր կարծիքի համար",
    },
    button_2: {
      en: "close",
      ru: "закрывать",
      hy: "Փակել",
    },
  },
};
export default langData;
